import { defaultUrlTransform } from 'react-markdown'

// URI's prefixed with '&' should always open in the current tab
const PREFIXED_URI_RE = /^&https?:/

// special URI's are just normal links that get opened in same tab:
const SPECIAL_URI_RE = /^(&https?:|#|\/logout|mailto:|tel:)/

// Normal URI's are a superset of the above that can includes normal http/https uris:
const NORMAL_URI_RE = /^(https?:|&https?:|mailto:|tel:|\/logout|#)/

const isPrefixedURI = (uri: string): boolean => PREFIXED_URI_RE.test(uri)

export const isNormalURI = (uri: string): boolean => NORMAL_URI_RE.test(uri)
export const isSpecialURI = (uri: string): boolean => SPECIAL_URI_RE.test(uri)

export const stripPrefixFromURI = (to: string): string => {
  return PREFIXED_URI_RE.test(to) ? to.substr(1) : to
}

export const transformMarkdownLinkURI = (uri: string): string => {
  if (isPrefixedURI(uri)) return uri
  else return defaultUrlTransform(uri)
}
